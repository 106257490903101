<template>
    <div class="right_button_box mobile">

        <div class="btns">
            <router-link tag="button" class="btn-1" :to="{path: '/recharge', query: {t: new Date().getTime()}}">입금</router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/exchange', query: {t: new Date().getTime()}}">출금</router-link>
<!--            <router-link tag="button" class="btn-1" :to="{path: '/customer_center', query: {t: new Date().getTime()}}">고객센터</router-link>-->
            <router-link tag="button" class="btn-1" :to="{path: '/notice', query: {t: new Date().getTime()}}">공지사항</router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/message', query: {t: new Date().getTime()}}">쪽지</router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/culcheck', query: {t: new Date().getTime()}}">출석부</router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/event', query: {t: new Date().getTime()}}">이벤트</router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/coupon', query: {t: new Date().getTime()}}">쿠폰</router-link>
            <router-link tag="button" class="btn-1" :to="{path: '/user_info', query: {t: new Date().getTime()}}">회원정보</router-link>

        </div>

    </div>
</template>

<script>
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";
    import {coffepay, owlevent, payback, run369} from "../network/userRequest";

    export default {
        name: "RightButtonsComp",
        methods:{
            payback(){
                this.$store.commit(RECEIVE_SHOW_LOADING);
                payback().then(res=>{
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: '페이백 '+res.data.msg+'P 지급 완료',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
            coffepay(){
                this.$store.commit(RECEIVE_SHOW_LOADING);
                coffepay().then(res=>{
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: '커피값&식사비 '+res.data.msg+'P 지급 완료\r\n\t\r\n\t' +
                                '커피값&식사비는 미니게임만 이용가능합니다 \n' +
                                '롤링300% 최소5회 배팅',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },

            owlevent(){
                this.$store.commit(RECEIVE_SHOW_LOADING);
                owlevent().then(res=>{
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: res.data.msg+'P 지급 완료\r\n\t\r\n\t' +
                                '올빼미 이벤트란 규정 필수 숙지 후 이용바랍니다.\n',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },
            run369(){
                this.$store.commit(RECEIVE_SHOW_LOADING);
                run369().then(res=>{
                    if (res.data.success) {
                        this.$store.dispatch('actionUserInfo')
                        this.$swal({
                            title: res.data.msg+'P 지급 완료\r\n\t\r\n\t' +
                                '[중요]돌발이벤트 규정 숙지 후 이용바랍니다.\n',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                    this.$store.commit(RECEIVE_HIDE_LOADING);
                })
            },

        }
    }
</script>

<style scoped>
    .right_button_box{
        width: 100%;
    }
    .right_button_box .btns{
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    .right_button_box .btns .btn-1{
        width: 24.6%;
        height: 42px;
        background: linear-gradient(180deg, #624f37, #34291a);
        color: #ffffff;
        margin: 1px 0;
        border-radius: 1px;
    }
    .right_button_box img{
       cursor: pointer;
    }

    @media screen and (max-width: 1024px) {

        .right_button_box{
            display: none!important;
        }
        .right_button_box{
            margin-top: 8px;
            padding: 8px 5px;
        }
        .right_button_box .btns{
            padding: 8px 0 !important;
        }
    }
</style>