<template>
  <div>
    <div class="go_back">
      <button class="btn03" @click="goback()">리스트 보기</button>
    </div>

    <div class="board_list" v-if="commentList.length>0">
      <div class="comments" v-for="(comment,idx) in commentList" :key="idx">
        <span class="nickname">{{comment.user.nickname}}</span>
        <span class="con">{{comment.content}}</span>
        <span class="time">{{comment.createTime|datef('MM/DD')}}</span>
      </div>
    </div>
    <div class="board_list" v-if="commentable">
      <div class="comments">
        <input class="comment_input" v-model="boardComment.content" placeholder="댓글을 입력하세요"/>
        <button class="btn02" @click="saveBoardComment()" style="font-size: 14px;font-weight: bold">댓글등록</button>
      </div>
    </div>
  </div>
</template>

<script>
  import {saveBoardComment} from "../network/userRequest";

  export default {
    name: "CommentsComp",
    props: {
      commentList: {
        type: Array,
        default() {
          return []
        }
      },
      commentable: {
        type: Number,
        default() {
          return 1
        }
      },
      boardId: 0,
    },
    data() {
      return {
        boardComment: {content: '', boardId: this.boardId}
      }
    },
    methods: {
      saveBoardComment() {
        saveBoardComment(this.boardComment).then(res => {
          if (this.boardComment.content.trim() === '') {
            this.$swal({
              title: '댓글 내용을 입력하세요',
              type: 'error',
              showCancelButton: false,
              showConfirmButton: true
            })
            return false;
          }
          if (res.data.success) {
            this.$swal({
              title: '댓글 작성이 완료되였습니다',
              type: 'success',
              showCancelButton: false,
              showConfirmButton: true
            })
            this.boardComment.content = ''
            this.title = ''
            this.$emit('commentSuccess')
          } else {
            this.$swal({
              title: res.data.msg,
              type: 'error',
              showCancelButton: false,
              showConfirmButton: true
            })
          }
        })

      },
      goback() {
        this.$router.go(-1)
      },
    }
  }
</script>

<style scoped>
  .go_back{
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .go_back button {
    width: 120px;
    font-size: 12px !important;
    margin: 10px 0;
    padding: 10px;
    background-color: grey;
  }

  .comments {
    box-sizing: border-box;
    padding: 5px 5px;
    text-align: center;
  }

  .comments .comment_input {
    border: 1px solid #f19f09;
    background-color: #e0e0e0;
    width: 100%;
    height: 30px;
    color: #1c1c25;
    outline: none;
  }

  .comments lable {
    line-height: 30px;
  }

  .comments button {
    width: 50%;
    margin-top: 10px;
    padding: 10px;
  }

  .comments span {
    line-height: 30px;
    border-bottom: 1px solid #2e313a;
    display: inline-block;
  }

  .comments .nickname {
    width: 20% !important;
    margin-left: 6px;
  }

  .comments .con {
    width: 50% !important;
    line-height: 30px;
    text-align: center;
  }

  .comments .time {
    width: 19%;
    text-align: right;
    margin-right: 6px;
  }
</style>